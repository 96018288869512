import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";

export class CompanyService{
    formDataCompany = {};
    constructor() {
        this.formDataCompany = {};
    }

    async getCountries(){
        let countries = Vue.prototype.$store.getters['common/countries'];
        if(countries.length <= 0){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.countries)
                .then((response) => {
                    Vue.prototype.$store.dispatch('common/setCountries', {value: response.data});
                    console.log(response.data);
                    return {countries: response.data};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {countries: []};
                })
        }
        return {countries: countries};
    }

    async getCompany(){
        return await axios.get(Vue.prototype.$globalSettings.api.endpoints.company.companyLoad)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, company: response.data.company};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', company: null};
            });
    }
    async saveCompany(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.company.companySave, this.formDataCompany).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.company.companySaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', company: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, company: response.data.company};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', company: null};
        });
    }

    async deleteCompany(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.company.companyDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}