<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="card card-primary">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit="saveForm">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group col-sm-6">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="name">Name *</label>
                                            <input type="text" class="form-control" id="name" placeholder="Enter your company name" v-model="formData.name" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                                            <label for="email">Email *</label>
                                            <input type="email" class="form-control" id="email" placeholder="Enter your company email" v-model="formData.email" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <label for="phone">Phone</label>
                                        <input type="text" class="form-control" id="phone" placeholder="Enter your company email" v-model="formData.phone">
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="address">Address *</label>
                                            <input type="text" class="form-control" id="address" placeholder="Enter your company address" v-model="formData.address" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="country_id">Country *</label>
                                            <select class="form-control" id="country_id" v-model="formData.country_id">
                                                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                                            </select>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="form-group col-sm-6">
                                        <div class="row">
                                            <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                                <label for="upload_image">Logo</label>
                                                <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                            </div>
                                            <div class="col-sm-6" v-if="formData.image">
                                                <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                                <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="card-footer">
                                <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Update</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../components/app/common/BreadCrumb";
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    import {CompanyService} from "@/services/company-service";
    let companyService = new CompanyService();
    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: 'Company_view',
        data: function(){
            return {
                pageName: 'Company',
                segments: [
                    { link: false, text: 'Company' },
                ],
                countries: [],
                upload_image: null,
                formData: {
                    name: null,
                    address: null,
                    country_id: 1,
                    email: null,
                    phone: null,
                    image: null,
                    date_format: 'DD/MM/YYYY',
                    currency: '$',
                    currency_prefix: 1,
                    invoice_thermal_print: 0,
                },
                isNew: false,
            }
        },
        props: {},
        components: {
            BreadCrumb, ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            loadCountries: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await companyService.getCountries();
                console.log(response.countries);
                this.countries = response.countries;
                loader.hide();
            },
            async loadSavedData(){
                let loader = this.$loading.show();
                let response = await companyService.getCompany();
                if(response.company) {
                    this.formData = response.company;
                    this.isNew = false;
                }else{
                    let user = this.$store.getters["auth/user"];
                    this.formData.email = user.email;
                    this.isNew = true;
                }
                loader.hide();

                this.formData['mode'] = 'company';
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                companyService.formDataCompany = this.formData;
                let response = await companyService.saveCompany(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});

                    let userInitResponse = await userService.userInit();
                    if(userInitResponse.isSuccess){
                        await this.$router.push({name: 'App.Dashboard'});
                    }else{
                        await this.$router.push({name: 'Login'});
                    }
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            }
        },
        mounted() {
            this.loadCountries();
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
